import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import Scoring from './Scoring';
import {
  setNextStep,
  toggleScoringModal,
  getScoring,
  setScoringQuota,
  resetScoring,
  restoreAvailableQuota,
  setApplyScoring,
  resetMegaQuota
} from '../../../actions/portal_financing_actions';
import { setCurrentStep, autoSkipStep } from '../../../actions/stepper_actions';

const mapStateToProps = state => {
  const pf = state.portalFinancing;
  return {
    supplierId:
      _get(pf, 'portalPOS[0].attributes.supplier.id') ||
      state.authentication.currentUser.supplier.id,
    isTransfer: pf.ongoingTransfer,
    scoringQuota: pf.scoringQuota,
    isCommercialSale: pf.is_commercial_sale,
    minimumScoringQuota: pf.minimumScoringQuota,
    process: state.stepper.process,
    routes: state.stepper.routes,
    useScoring: pf.useScoring,
    useMegaQuota: pf.useMegaQuota,
    scoringModal: pf.scoringModal,
    scoringLoading: state.requestState.requestScoringSend,
    expeditionDates: {
      debtor:
        pf.id_debtor_expedition_date ||
        _get(pf, 'promissory.debtor.id_expedition_date') ||
        pf.id_expedition_date,
      cosigner:
        pf.id_cosigner_expedition_date ||
        _get(pf, 'promissory.cosigner.id_expedition_date') ||
        pf.dc_cosigner_expedition_date
    },
    debtorCache: {
      debtorIdentificationType: pf.debtor_identification_type,
      debtorIdentification: pf.debtor_identification,
      debtorContractNumber: pf.debtor_contract_number,
      debtorBirthdate: pf.debtor_birthdate
    },
    cosignerCache: {
      cosignerIdentificationType: pf.cosigner_identification_type || null,
      cosignerIdentification: pf.cosigner_identification || null,
      cosignerContractNumber: pf.cosigner_contract_number || null,
      cosignerBirthdate: pf.cosigner_birthdate || null
    },
    gnpGasApplianceSaleEvaluation: pf.gnp_gas_appliance_evaluation,
    categoriesUsedToScoringMaximumValue: state.globalSetting.orgData.categories_to_use_scoring_extra_quota_maximun_value,
    isSecureSale: pf.isSecureSale,
    secureSaleMaxQuota: state.globalSetting.orgData.secure_sale_max_quota,
    specialSale: pf.special_sale,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNextStep: bindActionCreators(setNextStep, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    toggleScoringModal: bindActionCreators(toggleScoringModal, dispatch),
    getScoring: bindActionCreators(getScoring, dispatch),
    setScoringQuota: bindActionCreators(setScoringQuota, dispatch),
    resetScoring: bindActionCreators(resetScoring, dispatch),
    autoSkipStep: bindActionCreators(autoSkipStep, dispatch),
    restoreAvailableQuota: bindActionCreators(restoreAvailableQuota, dispatch),
    setApplyScoring: bindActionCreators(setApplyScoring, dispatch),
    resetMegaQuota: bindActionCreators(resetMegaQuota, dispatch),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Scoring);
