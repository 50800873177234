import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import { _moneyFormat } from '../../../helpers/utilityFunctions';
import StepperButtons from '../../shared/StepperButtons';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SegmentHeader from '../../shared/SegmentHeader';
import RadioField from '../../shared/Inputs/RadioField';
import OurDatePicker from '../../shared/CustomDatePicker';
import { history } from '../../../helpers/history';
import { withStyles } from '@material-ui/core/styles';
import formatMoney from '../../../helpers/formatMoney';
import { scoringSanitizer } from '../../../helpers/sanitizers/scoringSanitizer';
import { options } from './ScoringMetadata';
import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';
import CustomDialog from '../../shared/Dialogs/CustomDialog';
import { validationSchema } from './ScoringValidation';
import moment from 'moment';

class Scoring extends Component {
  state = {
    scoringDisabled: false
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  _goNext = values => {
    this.props.getScoring(
      scoringSanitizer(values, this.solidaryCosigner()),
      this.props.process
    );
  };

  _onCloseDialog = () => {
    this.props.toggleScoringModal(false);
  };

  _applyScoring = () => {
    const {scoringQuota} = this.props;
    this.props.resetMegaQuota();
    this.props.setNextStep(this.props.process);
    this.props.toggleScoringModal(false);
    this.props.setScoringQuota(scoringQuota);
    this.props.setApplyScoring(true);
    history.push(this.props.routes[this.nextStep]);
  };

  _skip = restoreAvQuota => {
    restoreAvQuota && !this.props.useMegaQuota && this.props.restoreAvailableQuota();
    this.props.resetScoring();
    this.props.setNextStep(this.props.process);
    history.push(this.props.routes[this.nextStep]);
  };

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);
    this.updateScoringDisabled();

    if (this.props.isCommercialSale) {
      this.props.autoSkipStep(this.props.location.pathname);
      this.props.resetScoring();
      this.props.setNextStep(this.props.process);
      history.push(this.props.routes[this.nextStep]);
    } 
  }

  parseDate(date) {
    if (date.trim() === '') {
      return null;
    } else {
      return moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD');
    }
  }

  solidaryCosigner() {
    return (
      !!this.props.cosignerCache.cosignerIdentification &&
      !this.props.cosignerCache.cosignerContractNumber
    );
  }

  updateScoringDisabled() {
    const { specialSale } = this.props;
    if (specialSale) {
      if ( specialSale.type === 'GNP' || specialSale.type === 'GASAPPLIANCE') {
        this.setState({ scoringDisabled: true });
      }
    }
  }

  render() {
    const {
      classes,
      useScoring,
      expeditionDates,
      scoringModal,
      scoringQuota,
      scoringLoading,
      debtorCache,
      cosignerCache,
      supplierId,
      categoriesUsedToScoringMaximumValue,
      isSecureSale
    } = this.props;
    const { scoringDisabled } = this.state;
    const categoriesExist = categoriesUsedToScoringMaximumValue && categoriesUsedToScoringMaximumValue.length !== 0;
    return (
      <div>
        <Paper>
          <Formik
            initialValues={{
              supplierId,
              useScoring: useScoring === true ? 'true' : 'false',
              debtorExpeditionDate: this.parseDate(expeditionDates.debtor),
              cosignerExpeditionDate: this.parseDate(expeditionDates.cosigner),
              cosignerPresent: !!cosignerCache.cosignerIdentification,
              ...debtorCache,
              ...cosignerCache
            }}
            validationSchema={validationSchema}
            onSubmit={this._goNext}
            render={({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
              errors
            }) => (
              <form autoComplete="off">
                <SegmentHeader className={classes.segmentHeader}>
                  USAR SCORING (OPCIONAL)
                </SegmentHeader>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item xs={12}>
                    <RadioField
                      options={options}
                      onChange={handleChange}
                      value={values.useScoring}
                      disabled={scoringDisabled}
                      name="useScoring"
                      classes={{
                        radioButton: classes.radioButtonContainer
                      }}
                    />
                  </Grid>
                </Grid>
                {values.useScoring === 'true' && (
                  <Fragment>
                    <SegmentHeader className={classes.segmentHeader}>
                      DATOS DEL DEUDOR
                    </SegmentHeader>
                    <Grid container spacing={24} alignItems="flex-start">
                      <Grid item sm={4}>
                        <OutlinedTextField
                          label="Tipo identificación del deudor:"
                          value={values.debtorIdentificationType}
                          disabled
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <OutlinedTextField
                          label="Identificación del deudor:"
                          value={values.debtorIdentification}
                          disabled
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <OurDatePicker
                          disabled={Boolean(expeditionDates.debtor)}
                          name={'debtorExpeditionDate'}
                          invalidLabel="Fecha inválida"
                          maxDateMessage="Fecha inválida"
                          minDateMessage="Fecha inválida"
                          invalidDateMessage={'Fecha inválida'}
                          label="Fecha expedición identificación del deudor:"
                          value={values.debtorExpeditionDate}
                          onChange={e =>
                            setFieldValue('debtorExpeditionDate', e)
                          }
                          onBlur={handleBlur}
                          onError={() =>
                            setFieldValue('debtorExpeditionDate', null)
                          }
                          error={
                            touched.debtorExpeditionDate &&
                            !!errors.debtorExpeditionDate
                          }
                          helperText={
                            touched.debtorExpeditionDate &&
                            errors.debtorExpeditionDate
                          }
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <OutlinedTextField
                          label="Fecha nacimiento del deudor:"
                          value={values.debtorBirthdate}
                          disabled
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <OutlinedTextField
                          label="Contrato del deudor:"
                          value={values.debtorContractNumber}
                          disabled
                        />
                      </Grid>
                      <Grid item sm={4} />
                    </Grid>
                  </Fragment>
                )}
                {values.useScoring === 'true' && values.cosignerPresent && (
                  <Fragment>
                    <SegmentHeader className={classes.segmentHeader}>
                      DATOS DEL CODEUDOR
                    </SegmentHeader>
                    <Grid container spacing={24} alignItems="flex-start">
                      <Grid item sm={4}>
                        <OutlinedTextField
                          label="Tipo identificación del codeudor:"
                          value={values.cosignerIdentificationType}
                          disabled
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <OutlinedTextField
                          label="Identificación del codeudor:"
                          value={values.cosignerIdentification}
                          disabled
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <OurDatePicker
                          disabled={Boolean(expeditionDates.cosigner)}
                          name={'cosignerExpeditionDate'}
                          invalidLabel="Fecha inválida"
                          maxDateMessage="Fecha inválida"
                          minDateMessage="Fecha inválida"
                          invalidDateMessage={'Fecha inválida'}
                          label="Fecha expedición identificación del codeudor:"
                          value={values.cosignerExpeditionDate}
                          onChange={e =>
                            setFieldValue('cosignerExpeditionDate', e)
                          }
                          onBlur={handleBlur}
                          onError={() =>
                            setFieldValue('cosignerExpeditionDate', null)
                          }
                          error={
                            touched.cosignerExpeditionDate &&
                            !!errors.cosignerExpeditionDate
                          }
                          helperText={
                            touched.cosignerExpeditionDate &&
                            errors.cosignerExpeditionDate
                          }
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <OutlinedTextField
                          label="Fecha nacimiento del codeudor:"
                          value={values.cosignerBirthdate}
                          disabled
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <OutlinedTextField
                          label="Contrato del codeudor:"
                          name={'cosignerContractNumber'}
                          value={values.cosignerContractNumber}
                          onChange={e => handleChange(e)}
                          error={
                            touched.cosignerContractNumber &&
                            !!errors.cosignerContractNumber
                          }
                          helperText={
                            touched.cosignerContractNumber &&
                            errors.cosignerContractNumber
                          }
                          disabled={!this.solidaryCosigner()}
                        />
                      </Grid>
                      <Grid item sm={4} />
                    </Grid>
                  </Fragment>
                )}
                <StepperButtons
                  allowOptional={values.useScoring === 'false'}
                  allowNext={values.useScoring === 'true'}
                  customLoading={scoringLoading}
                  onSubmit={handleSubmit}
                  goBack={this.props.goBack}
                  skip={() => this._skip(!this.props.isTransfer)}
                />
              </form>
            )}
          />
        </Paper>
        {scoringModal && (
          <CustomDialog
            onClose={this._onCloseDialog}
            open={scoringModal}
            disableBackdropClick={true}
            onConfirm={this._applyScoring}
            title={'Consulta Scoring'}
            buttonText={'APLICAR'}
          >
            <p>
              El cupo total utilizable con scoring es de <b>{formatMoney(scoringQuota)}</b>.
            </p>
            { categoriesExist && isSecureSale &&
              <p>
                <p>
                  Tiene disponible {formatMoney(scoringQuota)} de scoring para utilizar unicamente en las siguientes categorías:{' '}
                  <ul>
                    {categoriesUsedToScoringMaximumValue.map((cat) => <li>{cat.name}</li>)}
                  </ul>
                </p>
              </p>
            }
          </CustomDialog>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  dialog: {
    width: '100%',
    maxWidth: 600
  },
  dialogButton: {
    margin: 1 * theme.spacing.unit,
    marginTop: 0,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  radioButtonContainer: {},
  segmentHeader: {
    marginTop: 2 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  }
});

export default withStyles(combineStyles(styles, TypographyStyles))(Scoring);
